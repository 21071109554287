import React from "react";
import Layout from "../../components/layout";

const QuickCoachingPage = () => {
  return (
    <Layout>
      <main className="container">
        <div className="text-black">QuickCoachingPage</div>
      </main>
    </Layout>
  );
};

export default QuickCoachingPage;
